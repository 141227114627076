@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  background-color: var(--color-cyan);
  padding: 64px 0;

  @media (min-width: design-token("--viewport-m")) {
    padding: 80px 0;
  }

  @media (min-width: design-token("--viewport-l")) {
    padding: 120px 0;
  }
}

.title {
  color: var(--color-white);
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-m);
  margin: 0 0 24px;
  text-align: center;

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-xxxl);
    margin: 0 0 48px;
  }
}
