@design-tokens url('../../utils/media-queries.json') format('style-dictionary3');

.container {
  margin: 0;
  padding: 4px 14px 4px 20px;
  position: relative;

  &::before {
    content: "";
    background-color: var(--color-yellow);
    border-radius: var(--border-radius);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 6px;
  }

  @media (min-width: design-token("--viewport-m")) {
    padding: 8px 32px 8px 40px;

    &::before {
      width: 8px;
    }
  }
}

.rating {
  margin-bottom: 8px;

  @media (min-width: design-token("--viewport-m")) {
    margin-bottom: 16px;
  }
}

.quote {
  color: var(--color-grey-1);
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-m);
  margin: 0;

  @media (min-width: design-token("--viewport-m")) {
    font-size: var(--font-size-xxxl);
  }
}

.footer {
  color: var(--color-grey-1);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-m);
  margin: 16px 0 0;

  @media (min-width: design-token("--viewport-m")) {
    font-size: var(--font-size-m);
    margin: 24px 0 0;
  }
}
