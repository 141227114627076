@design-tokens url('../../utils/media-queries.json') format('style-dictionary3');

.item {
  border-bottom: solid 1px var(--color-grey-4);
  display: block;
  width: 100%;

  &:first-child {
    border-top: solid 1px var(--color-grey-4);
  }
}

.header {
  background-color: transparent;
  border: none;
  color: var(--color-grey-1);
  cursor: pointer;
  display: block;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  transition: color var(--transition);

  &:hover,
  &:focus {
    color: var(--color-cyan);
  }
}

.headerInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;

  & svg {
    fill: var(--color-cyan);
    height: 16px;
    margin-left: 16px;
    transform: rotate(0);
    transition: transform var(--transition);
    width: 16px;
    will-change: transform;
  }

  @media (min-width: design-token("--viewport-m")) {
    padding: 20px 0;

    & svg {
      margin-left: 20px;
      height: 20px;
      width: 20px;
    }
  }
}

.headerOpen svg {
  transform: rotate(180deg);
}

.headerTitle {
  color: currentColor;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-m);
  margin: 0;

  @media (min-width: design-token("--viewport-m")) {
    font-size: var(--font-size-xl);
  }
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition), padding var(--transition);
}

.contentOpen {
  max-height: 500px;
  padding: 0 0 12px;

  @media (min-width: design-token("--viewport-l")) {
    padding: 0 0 20px;
  }
}
