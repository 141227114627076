@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  color: var(--color-grey-1);
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-m);
  margin: 0;
  text-align: center;

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-xxxl);
  }
}
