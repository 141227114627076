@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  background-color: var(--color-cyan);
  color: var(--color-white);
  padding: 64px 0;

  @media (min-width: design-token("--viewport-l")) {
    padding: 80px 0;
  }
}

.title {
  color: var(--color-white);
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-m);
  margin: 0 0 24px;
  text-align: center;

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-xxxl);
    margin: 0 0 48px;
  }
}

.form {
  background-color: var(--color-white);
  border-radius: 100px;
  display: flex;
  overflow: hidden;
  padding: 4px;
  width: 100%;

  @media (min-width: design-token("--viewport-l")) {
    padding: 8px;
  }
}

.input {
  border-radius: 100px;
  border: none;
  color: var(--color-grey-1);
  font-size: 16px; /* Exception to prevent zoom on iOS */
  line-height: 1;
  outline: none;
  padding: 12px 16px;
  width: 100%;

  @media (min-width: design-token("--viewport-m")) {
    font-size: var(--font-size-m);
    padding: 12px 24px;
  }

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-l);
  }
}

.buttonLabel {
  display: none;

  @media (min-width: design-token("--viewport-m")) {
    display: block;
  }
}

.buttonIcon {
  height: 18px;
  width: 18px;

  & svg {
    height: 18px;
    width: 18px;
    transform: rotate(-90deg);
  }

  @media (min-width: design-token("--viewport-m")) {
    display: none;
  }
}

.error {
  background-color: var(--color-red);
  border-radius: var(--border-radius);
  color: var(--color-white);
  display: block;
  margin: 8px auto 0;
  max-width: 400px;
  padding: 8px 16px;
  text-align: center;
  width: 100%;
}

.subtext {
  color: var(--color-white);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  margin: 8px 0 0;
  text-align: center;

  @media (min-width: design-token("--viewport-m")) {
    font-size: var(--font-size-s);
  }

  @media (min-width: design-token("--viewport-l")) {
    margin: 12px 0 0;
  }
}
