@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.borderTopContainer {
  border-top: solid 4px var(--color-grey-5);
  padding-top: 64px;

  @media (min-width: design-token("--viewport-l")) {
    padding-top: 80px;
  }
}

.container {
  &:not(:first-child) {
    margin: 24px 0 0;
  }

  & > *:not(:last-child) {
    margin: 0 0 24px;
  }

  @media (min-width: design-token("--viewport-m")) {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;

    & > *:not(:last-child) {
      margin: 0;
    }
  }

  @media (min-width: design-token("--viewport-l")) {
    column-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 60px;

    &:not(:first-child) {
      margin: 32px 0 0;
    }
  }
}

.item {
  display: block;
  text-decoration: none;

  &:hover .title,
  &:focus .title {
    text-decoration: underline;
  }
}

.date {
  color: var(--color-grey-2);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  margin: 16px 0 8px;
}

.title {
  color: var(--color-cyan-hover);
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  margin: 0 0 4px;
  transition: color var(--transition);

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-xl);
    margin: 0 0 8px;
  }
}

.text {
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
  margin: 0;

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-m);
  }
}
