@design-tokens url('../../utils/media-queries.json') format('style-dictionary3');

.container {
  align-items: center;
  justify-content: space-between;

  @media (min-width: design-token("--viewport-m")) {
    display: flex;

    & > * {
      width: calc(50% - 16px);
    }
  }

  @media (min-width: design-token("--viewport-l")) {
    & > * {
      width: calc(50% - 70px);
    }
  }
}

.reversed {
  flex-direction: row-reverse;
}

.imageContainer {
  margin: 0 -20px 48px;

  @media (min-width: design-token("--viewport-m")) {
    margin: 0;
  }
}

.collapsibleContainer {
  margin: 12px 0 0;

  @media (min-width: design-token("--viewport-m")) {
    margin: 20px 0 0;
  }
}

.strongLinkContainer {
  margin: 12px 0 0;

  @media (min-width: design-token("--viewport-m")) {
    margin: 20px 0 0;
  }
}
