.wrapper {
  align-items: center;
  display: flex;
}

.stars {
  position: relative;
}

.inActiveStars {
  color: var(--color-grey-4);
  display: flex;
}

.activeStars {
  color: var(--color-orange);
  display: flex;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.icon {
  display: block;
}
