/* NOTICE: this is same as rich text, but with less options */

@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.title {
  color: var(--color-grey-1);
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-xl);
  line-height: 1;
  margin: 0;

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-xxl);
  }
}
