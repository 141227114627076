@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  background-color: var(--color-green);
  overflow: hidden;
  padding: 0 0 48px;
  position: relative;

  @media (min-width: design-token("--viewport-m")) {
    padding: 80px 0;
  }

  @media (min-width: design-token("--viewport-l")) {
    padding: 120px 0;
  }
}

.inner {
  position: relative;
}

.content {
  width: 100%;

  @media (min-width: design-token("--viewport-m")) {
    max-width: calc(50% - 40px);
  }
}

.title {
  color: var(--color-white);
  font-family: var(--font-family-compressed);
  font-size: var(--font-size-xxxl);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  margin: 48px 0 16px;
  padding-top: 22px;
  position: relative;

  & strong {
    color: var(--color-yellow);
    font-weight: var(--font-weight-bold);
  }

  @media (min-width: design-token("--viewport-xs")) {
    font-size: var(--font-size-xxxxl);
  }

  @media (min-width: design-token("--viewport-m")) {
    margin: 0 0 16px;
    font-size: 64px;
  }

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-xxxxxl);
    margin: 0 0 24px;
    padding-top: 28px;
  }

  @media (min-width: design-token("--viewport-xl")) {
    font-size: var(--font-size-xxxxxxxl);
  }

  &::before {
    content: "";
    background-color: var(--color-yellow);
    border-radius: var(--border-radius);
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 50px;

    @media (min-width: design-token("--viewport-l")) {
      height: 6px;
      width: 80px;
    }
  }
}

.description {
  font-weight: var(--font-weight-medium);

  @media (min-width: design-token("--viewport-l")) {
    width: 80%;
  }
}

.downButton {
  background-color: var(--color-yellow);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  height: 56px;
  margin: 16px 0 0;
  outline: none;
  position: relative;
  transition: background-color var(--transition);
  width: 56px;

  &:hover,
  &:focus {
    background-color: var(--color-yellow-hover);
  }

  & svg {
    fill: var(--color-grey-1);
    height: 25px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
  }

  @media (min-width: design-token("--viewport-l")) {
    margin: 24px 0 0;
    height: 64px;
    width: 64px;

    & svg {
      height: 28px;
      width: 28px;
    }
  }
}

.image {
  height: auto !important;
  margin: 0 0 0 -20px;
  max-width: inherit;
  width: calc(100% + 40px) !important;

  @media (min-width: design-token("--viewport-m")) {
    border-radius: 50%;
    height: 175% !important;
    position: absolute;
    left: calc(50% + 40px);
    top: 50%;
    transform: translate(0, -50%);
    width: auto !important;
  }
}
