@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  background-color: var(--color-grey-6);
  display: block;
  padding: 64px 0;
  text-decoration: none;

  @media (min-width: design-token("--viewport-m")) {
    padding: 80px 0;
  }

  @media (min-width: design-token("--viewport-l")) {
    padding: 120px 0;
  }
}

.card {
  background-color: var(--color-white);
  border-radius: var(--border-radius-l);
  overflow: hidden;

  @media (min-width: design-token("--viewport-m")) {
    display: flex;

    & > * {
      flex-shrink: 0;
      width: 50%;
    }
  }
}

.image {
  object-fit: cover;
  height: 100% !important;
  width: 100% !important;
}

.content {
  padding: 32px 20px;

  @media (min-width: design-token("--viewport-m")) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px 32px;
  }

  @media (min-width: design-token("--viewport-l")) {
    padding: 60px 40px;
  }

  @media (min-width: design-token("--viewport-xl")) {
    padding: 60px;
  }
}

.title {
  padding-top: 0 !important;

  @media (min-width: design-token("--viewport-m")) {
    padding-top: 22px !important;
  }

  @media (min-width: design-token("--viewport-l")) {
    padding-top: 28px !important;
  }

  &::before {
    display: none !important;

    @media (min-width: design-token("--viewport-m")) {
      display: block !important;
    }
  }
}

.strongLinkContainer {
  margin: 20px 0 0;
}
