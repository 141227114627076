@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.list {
  border-radius: var(--border-radius-l);
  overflow: hidden;
}

.showAll {
  background-color: var(--color-grey-6);
  color: var(--color-cyan);
  display: block;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  padding: 16px;
  text-align: center;
  text-decoration: none;
  transition: color var(--transition);

  &:hover,
  &:focus {
    color: var(--color-cyan-hover);
  }

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-l);
    padding: 24px;
  }
}

.row {
  background-color: var(--color-white);
  color: var(--color-grey-1);
  display: block;
  padding: 24px 20px;
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: solid 1px var(--color-grey-5);
  }

  @media (min-width: design-token("--viewport-m")) {
    display: flex;
    padding: 32px 24px;
  }
}

.dateContainer {
  color: var(--color-green);
  line-height: 1;
  margin: 0 0 4px;
  text-transform: uppercase;

  @media (min-width: design-token("--viewport-m")) {
    align-items: center;
    background-color: var(--color-grey-5);
    border-radius: 50%;
    color: var(--color-grey-1);
    display: flex;
    flex-shrink: 0;
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-medium);
    height: 64px;
    justify-content: center;
    margin: 0 20px 0 0;
    text-align: center;
    text-transform: none;
    width: 64px;
  }
}

.mobileDate {
  align-items: center;
  display: flex;

  @media (min-width: design-token("--viewport-m")) {
    display: block;
  }
}

.date {
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-bold);
  margin: 0 4px 0 0;

  @media (min-width: design-token("--viewport-m")) {
    margin: 0;
    font-size: var(--font-size-xl);
  }
}

.month {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-s);

  @media (min-width: design-token("--viewport-m")) {
    color: var(--color-grey-1);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-regular);
    line-height: 1;
  }
}

.contentContainer {
  @media (min-width: design-token("--viewport-l")) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.titleContainer {
  color: var(--color-grey-1);
  text-decoration: none;

  &:hover .title,
  &:focus .title {
    text-decoration: underline;
  }

  @media (min-width: design-token("--viewport-m")) {
    align-items: center;
    display: flex;
    margin: 0 0 8px;
  }
}

.title {
  color: var(--color-grey-1);
  font-family: var(--font-family-condensed);
  font-size: var(--line-height-l);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  margin: 0 0 4px;

  @media (min-width: design-token("--viewport-m")) {
    margin: 0;
  }
}

.labelsContainer {
  display: flex;
  margin: 0 0 4px;

  & > *:not(:last-child) {
    margin: 0 4px 0 0;
  }

  @media (min-width: design-token("--viewport-m")) {
    margin: 0 0 0 8px;
  }
}

.label {
  background-color: var(--color-grey-5);
  border-radius: var(--border-radius);
  color: var(--color-grey-1);
  display: block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  padding: 4px 6px;
}

.yellow {
  background-color: var(--color-yellow);
}

.information {
  color: var(--color-grey-2);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-m);
  }
}

.buttonContainer {
  margin: 16px 0 0;
}
