@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.list {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;

  @media (min-width: design-token("--viewport-m")) {
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  background-color: var(--color-grey-5);
  border-radius: var(--border-radius-l);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.imageContainer {
  display: block;
  position: relative;
}

.overlay {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 3%,
    rgba(0, 0, 0, 0) 100%
  );
  bottom: 0;
  height: 75%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.title {
  bottom: 20px;
  color: var(--color-white);
  font-family: var(--font-family-compressed);
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-regular);
  left: 20px;
  line-height: 1;
  margin: 0;
  padding-top: 22px;
  position: absolute;
  z-index: 2;

  & strong {
    color: var(--color-yellow);
    font-weight: var(--font-weight-bold);
  }

  &::before {
    content: "";
    background-color: var(--color-yellow);
    border-radius: var(--border-radius);
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 50px;

    @media (min-width: design-token("--viewport-l")) {
      height: 6px;
      width: 80px;
    }
  }

  @media (min-width: design-token("--viewport-m")) {
    font-size: var(--font-size-xxxl);
  }

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-xxxxl);
    padding-top: 28px;
    bottom: 32px;
    left: 32px;
  }
}

.content {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: design-token("--viewport-l")) {
    padding: 32px;
  }
}

.buttonReviewContainer {
  margin: 20px 0 0;

  @media (min-width: design-token("--viewport-l")) {
    align-items: center;
    display: flex;
    margin: 24px 0 0;
  }

  & > *:nth-child(2) {
    margin: 20px 0 0 0;

    @media (min-width: design-token("--viewport-l")) {
      margin: 0 0 0 24px;
    }
  }
}

.ratingContainer {
  align-items: center;
  display: flex;

  @media (min-width: design-token("--viewport-l")) {
    align-items: flex-start;
    flex-direction: column;
  }

  & > *:first-child {
    margin: 0 8px 0 0;

    @media (min-width: design-token("--viewport-l")) {
      margin: 0 0 4px;
    }
  }
}

.ratingLink {
  color: var(--color-grey-1);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-m);
  text-decoration: underline;
  transition: color var(--transition);

  &:hover,
  &:focus {
    color: var(--color-cyan);
  }

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-m);
  }
}
